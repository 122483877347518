const resources = {

    en: {
      translation: {
        "menu_header": {          
          "view_administrator": "Administrator View!",
          "precaution"        : "In this mode you can make advanced settings, keep caution!",
          "sure"              : "Yes, i´m sure",
          "back"              : "Return to normal view?",
          "confirm_back"      : "yes, back to normal view",
          "simple_mode"       : "Usr",
          "admin_mode"        : "Adm",
        },
        "header":{
          "my_labels"    : "My Labels",
          "my_catalogs"  : "My Catalogs",
          "my_users"     : "My Users",
          "configuration": "Configuration",
          "suscription"  : "Suscription (Coming soon)",
          "aplications"  : "Aplications",
          "logout"       : "Log out"
        },
        "main":{
            "welcome"          : "Welcome to Web2 barcode",
            "welcome_text"     : "We are glad to see you, we have made some changes to improve the experience of our users. We hope you like them! ",
            "login"            : "Log in",
            "industry_printing": "Industry printing",
            "and_more"         : "and more from your web browser.",
            "features"         : "Features",
            "we_great"         : "That we're great at",
            "print_popular"    : "Print to popular hardware",
            "using_files"      : "Using PDF, PNG, HTML, PCL, ESC/POS, EPL, ZPL, SBPL, FGL and more directly from your browser.",
            "serial"           : "Serial, HID, USB, Socket and File IO",
            "serial_2"         : "Communicate directly with popular hardware directly from your browser.",
            "simple_api"       : "Simple API, easy coding",
            "simple_api_2"     : "Download and edit sample.html to get started, or checkout our quickstart guide.",
            "were_famous"      : "We're famous for",
            "thermal_print"    : "Thermal Printing",
            "thermal_print_1"  : "Print barcodes, receipts and more from the web",
            "thermal_print_2"  : "Works with Zebra, SATO, Epson, Citizen, Star, Boca, Dymo, TSC, Ribetec and more",
            "thermal_print_3"  : "Supports a lot popular connection types like COM, Parallel, Serial, USB, IP (and more)",
            "thermal_print_4"  : "Compatible with Windows, MacOS, Linux clients and android",
            "thermal_print_5"  : "Tested with Firefox, Chrome, Safari, Microsoft Edge, Opera",
            "quick_setup"      : "Quick Setup",
            "quick_setup_1"    : "Seamlessly sends commands in EPL, ZPL, SBPL, FGL, ESC/POS, EPCL, CPCL (and many more)",
            "quick_setup_2"    : "Support",
            "quick_setup_3"    : "Commercial supported versions available",
            "quick_setup_4"    : "AJAX ready by using JavaScript to control print jobs",
            "quick_setup_5"    : "Supports direct JPG, PDF, PNG, HTML printing",
        },
        "login":{
            "welcome": "Welcome",
            "login_1": {
              "password"    : "Password",
              "not_account" : "Don't have an account? Create an account",
              "login_google": "Sign in with Google",
              "forgot_pw"   : "I forgot my password",
              "unverified"  : "Unverified email. Please check your email and verify your email to enter Web2 barcode",
              "invalid"     : "Wrong email or password, check the data entered or create a new account",
            },
            "login_2": {
              "create_account": "Create account",
              "name"          : "Name",
              "login"         : "If you have an account, login",
              "confirm_pw"    : "Confirm password",
            },
            "login_3":{
                "recover_pw" : "Recover password",
                "put_email"  : "Enter your email"
            },
        },
        "home":{
            "about"           : "About ... ",
            "welcome_home"    : "Welcome to React and react-i18next",
            "login"           : "Log in",
            "welcome_tutorial": "Video tutorial",
            "welcome_sweet"   : "Do you do tutorial?",
            "user"            : "User",
            "email"           : "Email",
            "actually"        : "Currently, you do not have the professional version",
            "privacy"         : "Privacy Policy",
            "local"           : "Local account",
            "google"          : "Google session",
            "profesional"     : "You have the professional version",
        },
        "utilidades": {
            "funciones" :{
                "error_text2barcode": "error hehe"
            },
            "yes"         : "Yes",
            "delete"      : "Delete",
            "catalogs"    : "Catalogs",
            "close"       : "Close",
            "name"        : "Name",
            "description" : "Description",
            "template"    : "Template",
            "code"        : "Code",
            "width"       : "Width",
            "height"      : "Height",
            "fill_fields" : "Fill fields",
            "save_changes": "Save changes",
            "search"      : "Search",
            "error_delete": "An error occurred",
            "view_all"    : "View all",
        },
        "welcome":{
            "welcome"                : "Welcome to Web2 Barcode",
            "welcome_text"           : "You can skip this tutorial, click on skip button",
            "continue"               : "continue",
            "skip"                   : "Skip",
            "skip_tutorial"          : "Skip tutorial?",
            "no_detect"              : 'Application "Text2 Barcode" was not detected',
            "starting_t2b"           : 'Starting Text2 Barcode',
            "step_1"                 : "Step 1 - Download Text2 Barcode",
            "step_2"                 : "Step 2 - Install Text2 Barcode",
            "step_3"                 : "Step 3 - Return to Web2 Barcode",
            "instructions"           : "No detect",
            "instructions_2"         : "No detect Text2 Barcode",
            "btn_download"           : "Download",
            "title_2"                : "follow next step and test print",
            "got_text2barcode"       : "I have Text2 Barcode",
            "test"                   : "Print test",
            "select_printer"         : "Select printer",
            "correct_print_title"    : "Print test success",
            "correct_print_desc"     : "Now, we go to labels design",
            "correct_print_ok"       : "First we will download a template from our catalog by clicking on the corresponding button",
            "demo_store_correct"     : "Download correctly",
            "start_obligatory_t2b"   : "You need start Text2 Barcode",
            "redirect"               : "Redirect to My labels",
            "redirect_download"      : "Download Complete",
            "tutorial_concluded"     : "Tutorial complete",
            "tutorial_concluded_text": "tutorial complete",
            "filters_tutorial"       : "First we will download a template from our catalog by clicking on the corresponding button",
            "filters"                : "You can use these filters",
            "filters_text"           : "You can empty filters",
            "video_tutorial"         : "Video tutorial install Web2 Barcode",
            "huawei"                 : "Device Huawei detected",
            "huawei_message"         : "In Huawei devices we need to disable automatic battery management, see the next video tutorial",
            "wait"                   : "Read instructions!",
            "android_view"           : "Now we will find our printer and perform a test print",
        },
        "see_labels":{ // see-labels / Ver-etiquetas / VerEtiquetas        
          "title"         : "My labels",
          "templates"     : "Add new templates",
          "create"        : "Create labelt",
          "print"         : "Print",
          "print_2"       : "Select Printer",
          "language"      : "Language",
          "size"          : "Size",
          "printer"       : "Printer",
          "complete_form" : "Fill out the form",
          "add_catalog"   : "Select catalog",
          "remove_catalog": "Remove catalog",
          "edit_template" : "Edit template",
          "clone_template": "Clone template",
          "has_catalog"   : "Includes Catalog",
          "change_printer": "Change printer?",
          "delete"        : "Delete Template?",
          "delete_2"      : "This action cannot be undone",
          "delete_3"      : "Label deleted succesfully",
          
        },
        "edit_label":{
            "image_pre": "Image preview",
            "update"   : "Update template",
        },
        "config_label":{ //label-configuration / configuracion-etiqueta / ConfiguracionEtiqueta
          "config_label"     : "Template configuration",
          "variables"        : "Variables",
          "form"             : "Form",
          "support_variables": "SUPPORT VARIABLES",
          "prices"           : "Prices",
          "choose_var"       : "Choose variable",
          "penny_separator"  : "Penny separator",
          "line_break"       : "Line break",
          "chr_4_line"       : "number of characters per line",
          "save_changes"     : "Save Changes",
          "save_prices"      : "Save Changes on price",
          "active"           : "Active",
          "disabled"         : "Disabled",
          "only_zpl"         : "Only works on ZPL!",
          "currency"         : "Set the currency symbol",
          "structure"        : "This is the structure that the currency must carry",
        },
        "catalog":{ //Catalogo / catalog / catalog of templates
          "filters"  : "Filters",
          "templates": "Templates",
          "data_base": "Databases",
          "sizes"    : "Sizes",
          "propose"  : "Intention",
          "language" : "Language",
          "filter"   : "Apply filter",
          "clean"    : "Clean filter",
          "cat_demo" : "Includes DEMO catalog",


        },
        "import":{ //Importar / import / upload
          "t_import"     : "Upload File",
          "select_file"  : "Select a catalog from your device in <b>CSV</b> or <b>XLS</b> format",
          "save_template": "Save catalog template",
          "title_import" : "Select your rows and columns",
          "use_header"   : "Use first row as header",
          "related_label": "Related Labels",
          "not_related"  : "Not label related",
          "how_to_use"   : "How to use a database? (csv, xls (excel) )",
        },
        "catalogs":{ //Catalogs / catalogos
          "catalogs_title" : "Catalogs",
          "catalog"        : "Catalog",
          "import"         : "Import catalog template",
          "not_found"      : "Templates not found",
          "header"         : "Header",
          "select_header"  : "Select headers",
          "first_row"      : "First row as header",
          "not_first_row"  : "Without header",
          "update"         : "Update Template",
          "download"       : "Download Template",
          "go_to_my_labels": "Success - Go to My labels",
        },
        "view":{
          "find_print"     : "Find & print",
          "description_1"  : "Select the fields and quantities to print large quantities of labels",
          "description_2"  : "Quickly print by finding the match or double click on the row you need to print",
          "more"           : "More",
          "select_at_least": "Select at least one row",
          
        },
        "menu":{
          "my_labels": 'My labels',
          "catalog"  : "Web Templates / Download new labels"
        },
      } 
    },

    es: {
      translation: {
        "menu_header": {          
          "view_administrator": "¡Vista de administrador!",
          "precaution"        : "En este modo usted puede realizar configuraciones avanzadas, ¡Tome sus precauciones!.",
          "sure"              : "Si, estoy seguro.",
          "back"              : "¿Regresar a vista normal?",
          "confirm_back"      : "Si, regresar a vista normal",
          "simple_mode"       : "Usr",
          "admin_mode"        : "Adm",
        },
        "header":{
          "my_labels"    : "Mis Etiquetas",
          "my_catalogs"  : "Mis Catálogos",
          "my_users"     : "Mis Usuarios",
          "configuration": "Configuración",
          "suscription"  : "Suscripción (próximamente)",
          "aplications"  : "Aplicaciones",
          "logout"       : "Cerrar sesión"
        },
        "main":{
            "welcome"          : "Bienvenido a Web2 Barcode",
            "welcome_text"     : "Nos alegra verte, estaremos realizando cambios sin previo aviso para mejorar la experiencia de nuestros usuarios ¡Esperamos que te agraden!",
            "login"            : "Iniciar Sesión",
            "industry_printing": "Impresión en la industria",
            "and_more"         : "Y más para tu navegador web",
            "features"         : "Características",
            "we_great"         : "Somos muy buenos en",
            "print_popular"    : "Imprime en los equipos más populares",
            "using_files"      : "Usa archivos PDF, PNG, HTML, PCL, ESC/POS, EPL, ZPL, SBPL, FGL ¡Y más! desde tu navegador.",
            "serial"           : "Serial, HID, USB, Socket y archivos IO (entrada y salida)",
            "serial_2"         : "Comuníquese directamente con hardware popular directamente desde su navegador.",
            "simple_api"       : "API simple, fácil codificación",
            "simple_api_2"     : "Descargue y edite sample. html para comenzar, o consulte nuestra guía de inicio rápido.",
            "were_famous"      : "Somos famosos haciendo",
            "thermal_print"    : "Imprime en los equipos más populares",
            "thermal_print_1"  : "Imprima códigos de barras, recibos y más desde la web",
            "thermal_print_2"  : "Trabaje en equipos como Zebra, SATO, Epson, Citizen, Star, Boca, Dymo, TSC, Ribetec y más",
            "thermal_print_3"  : "Soporte para las conexiones mas usadas como, COM, puerto paralelo y serial, USB, IP y más",
            "thermal_print_4"  : "Compatible con Windows, MacOS, Linux y Android",
            "thermal_print_5"  : "Con los siguientes navegadores compatibles, Firefox, Chrome, Safari, Microsoft Edge, Opera",
            "quick_setup"      : "Configuración inicial ",
            "quick_setup_1"    : "Envía comandos en  EPL, ZPL, TSPL, ESC/POS, EPCL, CPCL",
            "quick_setup_2"    : "Soporte",
            "quick_setup_3"    : "Soporte comercial para algunas versiones",
            "quick_setup_4"    : "Listo para usar con AJAX usando JavaScript para controlar los trabajos de impresión",
            "quick_setup_5"    : "Soporte directo para JPG, PDF, PNG, HTML impresión",
        },
        "login":{
            "welcome": "Bienvenido ",
            "login_1": {
              "password"    : "Contraseña",
              "not_account" : "¿No tiene cuenta? crear una",
              "login_google": "Iniciar sesión con Google",
              "forgot_pw"   : "Olvide mi contraseña",
              "unverified"  : "Correo electrónico sin verificar. Por favor revise su correo y verifique su correo para ingresar a Web2 Barcode",
              "invalid"     : "Correo o contraseña incorrectos, verifique los datos ingresados o creé una nueva cuenta",
            },
            "login_2": {
              "create_account": "Crear cuenta",
              "name"          : "Nombre",
              "login"         : "Si ya tiene una cuenta, inicie sesión",
              "confirm_pw"    : "Confirmar contraseña",
            },
            "login_3":{
                "recover_pw" : "Recuperar contraseña",
                "put_email"  : "Ingresa tu correo"
            },
        },
        "home": {
            "about"           : "Acerca de ... ",
            "welcome_home"    : "Bienvenido a Web2 Barcode ",
            "login"           : "Iniciar Sesión",
            "welcome_tutorial": "Vídeo tutorial",
            "welcome_sweet"   : "¿Desea realizar el tutorial nuevamente?",
            "user"            : "Usuario",
            "email"           : "Correo",
            "actually"        : "Actualmente, usted no cuenta con la versión profesional",
            "privacy"         : "Política de privacidad",
            "local"           : "Cuenta local",
            "google"          : "Sesión de Google",
            "profesional"     : "Usted cuenta con la versión profesional",
        },
        "utilidades": {
            "funciones" :{
                "error_text2barcode": "error debe iniciar el servicio de text2barcode o instalarlo en el siguiente enlace: "
            },
            "yes"         : "Si",
            "delete"      : "Eliminar",
            "catalogs"    : "Catálogos",
            "close"       : "Cerrar",
            "name"        : "Nombre",
            "description" : "Descripción",
            "template"    : "Plantilla",
            "code"        : "Código",
            "width"       : "Ancho",
            "height"      : "Alto",
            "fill_fields" : "Llene los campos",
            "save_changes": "Guardar cambios",
            "search"      : "Buscar",
            "error_delete": "Ocurrio un error en la eliminacion",
            "view_all"    : "Mostrar todo",
        },
        "welcome":{
          "welcome"                : "Bienvenido a Web2 Barcode",
          "welcome_text"           : "Te daremos un primer recorrido y te enseñaremos a imprimir tus primeras etiquetas, \n Si desea omitir este tutorial, de click en el botón omitir",
          "continue"               : "Comenzar recorrido",
          "skip"                   : "Omitir",
          "skip_tutorial"          : "¿Omitir Tutorial?",
          "no_detect"              : 'No se detecto la aplicación "Text2 Barcode"',
          "starting_t2b"           : 'Iniciar Text2barcode',
          "step_1"                 : "Paso 1 - Descargar Text2 Barcode",
          "step_2"                 : "Paso 2 - Instalar Text2 Barcode",
          "step_3"                 : "Paso 3 - Regresar a Web2 Barcode",
          "instructions"           : 'Web2 Barcode requiere <b>Text2 Barcode</b> como servicio de impresión. Si tienes Text2 Barcode inicia el servicio y da click en el siguiente botón: ',
          "instructions_2"         : 'En caso de <b>NO</b> tener Text2 Barcode instalado sigue los siguientes pasos',
          "btn_download"           : "Descargar",
          "title_2"                : "Sigue los dos siguientes pasos del video de instalación de text2 barcode y finaliza con una prueba de impresión",
          "got_text2barcode"       : "Ya tengo Text2 Barcode",
          "test"                   : "Prueba de impresión",
          "select_printer"         : "Seleccione una impresora",
          "correct_print_title"    : "Prueba de impresión correcta",
          "correct_print_desc"     : "Ahora pasaremos a usar nuestros diseños de etiquetas ",
          "correct_print_ok"       : "Primero descargaremos una plantilla de nuestro catálogo web.",
          "demo_store_correct"     : "Descarga correcta",
          "start_obligatory_t2b"   : "Debes iniciar Text2 Barcode",
          "redirect"               : "Ya descargaste tu primer formato, ahora podrás imprimirlo desde el modulo mis etiquetas.",
          "redirect_download"      : "A continuación seras redireccionado a mis etiquetas. ",
          "tutorial_concluded"     : "Tutorial concluido",
          "tutorial_concluded_text": "Se completo el tutorial, si quiere volver a verlo vaya al apartado de 'usuario / acerca de...' o de click en el logotipo de la aplicación ",
          "filters_tutorial"       : "Antes de descargar una plantilla, podemos configurar el tipo de plantilla que necesitamos mediante filtros",
          "filters"                : "Usted puede añadir filtros a la búsqueda para encontrar su plantilla preferida ",
          "filters_text"           : "o bien no seleccionar casillas para mostrar todas",
          "video_tutorial"         : "Video tutorial instalación text2 barcode",
          "huawei"                 : "Dispositivo Huawei detectado",
          "huawei_message"         : "En dispositivos Huawei necesitamos desactivar la gestión automatica de bateria, vea el siguiente vídeo tutorial",
          "wait"                   : "¡Lea las instrucciones!",
          "android_view"           : "Si utilizas impresora portatil bluetooth realiza la vinculación con el dispositivo. Ahora buscaremos nuestra impresora y realizaremos una prueba de impresión",
        },
        "see_labels":{ // Ver-etiquetas / VerEtiquetas        
          "title"         : "Mis Etiquetas",
          "templates"     : "Agregar nuevas etiquetas",
          "create"        : "Crear etiqueta",
          "print"         : "Imprimir",
          "print_2"       : "Seleccionar impresora",
          "language"      : "Lenguaje",
          "size"          : "Medidas",
          "printer"       : "Impresora",
          "complete_form" : "Llene los campos",
          "add_catalog"   : "Selecccionar catalogo",
          "remove_catalog": "Quitar catalogo",
          "edit_template" : "Editar etiqueta",
          "clone_template": "Clonar etiqueta",
          "has_catalog"   : "Tiene catalogo",
          "change_printer": "¿Cambiar impresora?",
          "delete"        : "¿Eliminar Plantilla?",
          "delete_2"      : "Esta acción no se puede revertir",
          "delete_3"      : "Se elimino la etiqueta correctamente",
          
        },
        "edit_label":{
            "image_pre": "Imagen previsualización",
            "update"   : "Actualizar etiqueta",
        },
        "config_label":{ //label-configuration / configuracion-etiqueta / ConfiguracionEtiqueta
          "config_label"     : "Configuración de etiquetas",
          "variables"        : "Variables",
          "form"             : "Formulario",
          "support_variables": "VARIABLES DE APOYO",
          "prices"           : "Precios",
          "choose_var"       : "Elegir variable",
          "penny_separator"  : "Separador de centavos",
          "line_break"       : "Salto de linea",
          "chr_4_line"       : "Caracteres por linea",
          "save_changes"     : "Guardar cambios en formulario",
          "save_prices"      : "Guardar cambios en precios",
          "active"           : "Activado",
          "disabled"         : "Desactivado",
          "only_zpl"         : "¡Solo funciona con ZPL!",
          "currency"         : "Configura el simbolo de divisa",
          "structure"        : "Esta es la estructura que debe llevar la divisa de la moneda",
        },
        "catalog":{ //Catalogo / catalog / catalog of templates
          "filters"  : "Filtros",
          "templates": "Descarga de etiquetas",
          "data_base": "Base de datos",
          "sizes"    : "Medidas",
          "propose"  : "Propósito",
          "language" : "Lenguaje",
          "filter"   : "Aplicar filtrar",
          "clean"    : "Limpiar filtros",
          "cat_demo" : "Incluye catalogo DEMO",


        },
        "import":{ //Importar / import / upload
          "t_import"     : "Subir catalogo",
          "select_file"  : "Selecciona un catalogo desde tu dispositivo en formato <b>CSV</b> o <b>XLS</b> ",
          "save_template": "Guardar plantilla de catalogo ",
          "title_import" : "Seleccionar las filas y las columnas para importar ",
          "use_header"   : "Usar primer fila como encabezado ",
          "related_label": "Etiquetas relacionadas ",
          "not_related"  : "Sin etiquetas relacionadas ",
          "how_to_use"   : "¿Como usar una base de datos? (csv, xls (excel) )",
        },
        "catalogs":{ //Catalogs / catalogos
          "catalogs_title" : "Catálogos",
          "catalog"        : "Catalogo",
          "import"         : "Importar catalogo",
          "not_found"      : "No hay catálogos para mostrar",
          "header"         : "Encabezado",
          "select_header"  : "Seleccionar encabezados",
          "first_row"      : "Primer fila con encabezado",
          "not_first_row"  : "Sin encabezado",
          "update"         : "Actualizar catalogo",
          "download"       : "Descargar catalogo",
          "go_to_my_labels": "Se agrego a Mis etiquetas",
        },
        "view":{
          "find_print"     : "Encuentra & imprime",
          "description_1"  : "Selecciona los campos y cantidades para mandar a imprimir grandes cantidades de etiquetas",
          "description_2"  : "Imprime rápidamente encontrando la coincidencia o da doble click sobre la fila que necesite imprimir",
          "more"           : "Más",
          "select_at_least": "Seleccione al menos una fila",
          
        },      
        "menu":{
          "my_labels": 'Mis etiquetas',
          "catalog"  : "Catalogo web / Descargar etiquetas"
        } , 
                
      }
    }

  };

export default resources;